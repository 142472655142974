<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">會員登入</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                會員登入
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8 bg-light">
      <div class="container">
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-xs-12 col-lg-4 mt-5" style="min-width: 400px">
            <div class="card bg-white card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-light mtn-90 pt-6">
                  <i
                    class="fa fa-user text-light font-size-72"
                    aria-hidden="true"
                  ></i>
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <footer
                    class="blockquote-footer text-uppercase font-size-32 mb-3"
                  >
                    會員登入
                  </footer>
                  <div>
                    <div class="form-group form-group-icon">
                      <input
                        v-model="count.account"
                        type="text"
                        class="form-control border"
                        placeholder="使用者名稱"
                        required=""
                        autocomplete="off"
                      />
                    </div>

                    <div class="form-group form-group-icon">
                      <input
                        v-model="count.password"
                        type="password"
                        class="form-control border password"
                        placeholder="密碼"
                        required=""
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group">
                      <button
                        @click="login"
                        type="button"
                        class="btn btn-danger text-uppercase w-100"
                      >
                        登入
                      </button>
                    </div>
                    <div class="clearfix small">
                      <label class="float-left checkbox-inline"
                        ><input type="checkbox" /> 記住我</label
                      >
                      <router-link to="/forgot" class="float-right">忘記密碼？</router-link>
                    </div>
                    <div class="or-seperator"><i>or</i></div>
                    <div class="text-danger text-left" style="font-size: 14px;"><b>若在 moneybar 是用 Facebook 或 Gmail 登入的會員，請使用下方的 Facebook、Gmail 按鍵註冊登入喔。</b></div>
                    <div class="form-group mt-2">
                      <button type="button" class="btn btn-block btn-danger text-uppercase mb-2" data-toggle="modal" data-target="#checkMoneybarMember">
                        我是 moneybar 會員
                      </button>
                      <FBLoginButton @FBLoginSuccess="loginWithFB" />
                      <GoogleLoginButton @GoogleLoginSuccess="loginWithGoogle" />
                    </div>

                    <div class="form-group text-center text-secondary mb-0">
                      <p class="text-center text-muted mt-5">
                        還不是會員嗎？
                        <router-link to="/signUp" class="text-danger">立即註冊！</router-link>
                      </p>
                    </div>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="checkMoneybarMember" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">查詢 MoneyBar 會員</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"
                  ><i class="fa fa-envelope" aria-hidden="true"></i
                ></span>
              </div>
              <input
                v-model="checkMoneybarAccount.email"
                type="email"
                class="form-control"
                placeholder="MoneyBar Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"
                  ><i class="fa fa-envelope" aria-hidden="true"></i
                ></span>
              </div>
              <input
                v-model="checkMoneybarAccount.password"
                type="password"
                class="form-control"
                placeholder="MoneyBar 密碼"
                aria-label="password"
                aria-describedby="basic-addon1"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="checkMoneybarMember">查詢</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import FBLoginButton from '@/components/FBLoginButton.vue'
import GoogleLoginButton from '@/components/GoogleLoginButton.vue'
export default {
  name: 'login',
  components: {
    FBLoginButton,
    GoogleLoginButton
  },
  data () {
    return {
      enterToken: '',
      memberId: '',
      count: {
        token: tokenGenerator(),
        account: '',
        password: '',
        socialType: '',
        socialId: ''
      },
      checkMoneybarAccount: {
        token: tokenGenerator(),
        email: '',
        password: ''
      }
    }
  },
  created () {
    const vm = this
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

    if (localStorage.getItem('memberToken') !== '') {
      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: localStorage.getItem('memberToken')
      })
        .then((res) => {
          if (res.data.status === '000') {
            alert('請先登出，再登入')
            vm.$router.push('/member')
          }
        })
    }
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    login () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/login`

      vm.axios.post(api, vm.count)
        .then((res) => {
          if (res.data.status !== '000') {
            alert(res.data.err_msg)
          } else {
            alert('登入成功')
            vm.enterToken = res.data.token
            localStorage.setItem('memberToken', vm.enterToken)
            vm.axios.post(`${process.env.VUE_APP_APIPATH}/iPlay/member/check`, {
              token: tokenGenerator(),
              memberToken: vm.enterToken
            })
              .then((res) => {
                vm.memberId = res.data.content.id
                localStorage.setItem('memberId', vm.memberId)
                if (res.data.content.grade === 0) {
                  vm.$router.push('/fqtest')
                } else {
                  vm.$router.push('/member')
                }
              })
          }
        })
    },
    loginWithFB (payload) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/login`
      console.log(payload)
      if (payload.email === undefined) {
        alert('您必須提供 Facebook 的 Email 以便註冊')
        return false
      }

      // 登入 fb 的 payload 資料傳進來
      vm.count.socialType = 'fb'
      vm.count.socialId = payload.id

      window.regInfo = {
        ...payload,
        socialType: 'fb'
      }

      vm.axios.post(api, {
        ...payload,
        token: tokenGenerator(),
        socialType: 'fb',
        socialId: payload.id
      })
        .then((res) => {
          console.log(res)
          // 登入成功且有註冊過
          if (res.data.status === '005') {
            alert(res.data.errmsg)
          } else if (res.data.status !== '002') {
            // 取得 response 的 token
            vm.enterToken = res.data.token
            // 存入 localStorage
            localStorage.setItem('memberToken', vm.enterToken)
            // 打 member API 取得 memberId 和 FQ 等級
            vm.axios.post(`${process.env.VUE_APP_APIPATH}/iPlay/member/check`, {
              token: tokenGenerator(),
              memberToken: vm.enterToken
            })
              .then((res) => {
                // 取得 memberId
                vm.memberId = res.data.content.id
                // 存入 localStorage
                localStorage.setItem('memberId', vm.memberId)
                if (res.data.content.grade === 0) {
                  vm.$router.push('/fqtest')
                } else {
                  vm.$router.push('/member')
                }
              })
          } else {
            // 登入之後且尚未註冊，跳轉到註冊頁，並把 payload 帶過去
            vm.$router.push('/signUp')
          }
        })
    },
    loginWithGoogle (payload) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/login`

      // 登入 google 的 payload 資料傳進來
      vm.count.socialType = 'google'
      vm.count.socialId = payload.id

      window.regInfo = {
        ...payload,
        socialType: 'google'
      }

      // 打 login API
      vm.axios.post(api, {
        ...payload,
        token: tokenGenerator(),
        socialType: 'google',
        socialId: payload.id
      })
        .then((res) => {
          console.log(res)
          if (res.data.status === '005') {
            alert(res.data.errmsg)
          } else if (res.data.status !== '002') {
            // 登入成功且有註冊過
            // 取得 response 的 token
            vm.enterToken = res.data.token
            // 存入 localStorage
            localStorage.setItem('memberToken', vm.enterToken)
            // 打 member API 取得 memberId 和 FQ 等級
            vm.axios.post(`${process.env.VUE_APP_APIPATH}/iPlay/member/check`, {
              token: tokenGenerator(),
              memberToken: vm.enterToken
            })
              .then((res) => {
                // 取得 memberId
                vm.memberId = res.data.content.id
                // 存入 localStorage
                localStorage.setItem('memberId', vm.memberId)
                if (res.data.content.grade === 0) {
                  vm.$router.push('/fqtest')
                } else {
                  vm.$router.push('/member')
                }
              })
          } else {
            // 登入之後且尚未註冊，跳轉到註冊頁，並把 payload 帶過去
            vm.$router.push('/signUp')
          }
        })
    },
    checkMoneybarMember () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/checkMoneyBarMember`

      if (vm.checkMoneybarAccount.email === '' || vm.checkMoneybarAccount.password === '') {
        alert('欄位均不可為空')
        return false
      }

      vm.axios.post(api, vm.checkMoneybarAccount)
        .then((res) => {
          if (res.data.status !== '000') {
            alert(res.data.err_msg)
          } else {
            if (res.data.token === undefined) {
              sessionStorage.setItem('responseMoneybar', JSON.stringify(res.data.content))
              vm.$router.push('/signUp')
            } else {
              localStorage.setItem('memberToken', res.data.token)
              vm.$router.push('/member')
            }
          }
        })
    }
  }
}
</script>
